import { getBatteryTypes } from '@/api/batteryTypes'

const state = {
  batteryTypes: [],
}

const getters = {
  GET_BATTERY_TYPES (state) {
    return state.batteryTypes
  },
}

const actions = {
  async AC_GET_BATTERY_TYPES({ commit }) {
    await getBatteryTypes()
      .then(response => {
        const { data } = response
        commit('SET_BATTERY_TYPES', data.data.batteryTypes)
      })
  },
}

const mutations = {
  SET_BATTERY_TYPES (state, data) {
    state.batteryTypes = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
