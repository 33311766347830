import { getConservations } from '@/api/conservations'

const state = {
  conservations: [],
}

const getters = {
  GET_CONSERVATIONS (state) {
    return state.conservations
  },
}

const actions = {
  async AC_GET_CONSERVATIONS({ commit }) {
    await getConservations()
      .then(response => {
        const { data } = response
        commit('SET_CONSERVATIONS', data.data.conservations)
      })
  },
}

const mutations = {
  SET_CONSERVATIONS (state, data) {
    state.conservations = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
