import Vue from 'vue'
import Vuex from 'vuex'

import auth from './modules/auth.module'
import alert from './modules/alert.module'
import snack from './modules/snack.module'
import ensayos from './modules/ensayos.module'
import ensayosPendientes from './modules/ensayosPendientes.module'
import ensayosCanales from './modules/ensayosCanales.module'
import ensayosConsulta from './modules/ensayosConsulta.module'
import usuarios from './modules/usuarios.module'
import canales from './modules/canales.module'
import claves from './modules/claves.module'
import conservaciones from './modules/conservaciones.module'
import laboratorios from './modules/laboratorios.module'
import normas from './modules/normas.module'
import tiposCanal from './modules/tiposCanal.module'
import tiposIncidencia from './modules/tiposIncidencia.module'
import tiposPila from './modules/tiposPila.module'
import integraciones from './modules/integraciones.module'
import integracionesErrores from './modules/integracionesErrores.module'
import pilasCapacidad from './modules/pilasCapacidad.module'
import pilasAccumulates from './modules/pilasAccumulates.module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    Auth: auth,
    Alert: alert,
    Snack: snack,
    Ensayos: ensayos,
    EnsayosPendientes: ensayosPendientes,
    EnsayosCanales: ensayosCanales,
    EnsayosConsulta: ensayosConsulta,
    Usuarios: usuarios,
    Canales: canales,
    Claves: claves,
    Conservaciones: conservaciones,
    Laboratorios: laboratorios,
    Normas: normas,
    TiposCanal: tiposCanal,
    TiposIncidencia: tiposIncidencia,
    TiposPila: tiposPila,
    Integraciones: integraciones,
    IntegracionesErrores: integracionesErrores,
    PilasCapacidad: pilasCapacidad,
    PilasAccumulates: pilasAccumulates,
  }
})
