import { getPending } from '@/api/batteries'

const state = {
  batteries: [],
  filtersDirty: false,
  filters: {
    calceled: false,
    programmedDate: {
      min: null,
      max: null,
    },
  },
}

const getters = {
  GET_FILTERS (state) {
    return state.filters
  },

  GET_BATTERIES (state) {
    return state.batteries.filter(battery => 1 === battery.test.testState._id)
  },
}

const actions = {
  async AC_GET_PENDING_BATTERIES ({ commit }) {
    await getPending(state.filters).then(response => {
      const { data } = response
      commit('SET_BATTERIES', data.data.batteries)
    })
  },

  AC_INIT_FILTERS ({state, commit}) {
    if (!state.filtersDirty) {
      let programmedDate = new Date().toISOString().slice(0, 10)

      let data = {
        programmedDate: {
          min: null,
          max: programmedDate,
        }
      }

      commit('SET_FILTERS', data)
      commit('SET_FILTERS_DIRTY', true)
    }
  },

  AC_SET_FILTERS ({commit}, data) {
    commit('SET_FILTERS', data)
  },
}

const mutations = {
  SET_FILTERS (state, data) {
    state.filters = data
  },

  SET_FILTERS_DIRTY (state, data) {
    state.filtersDirty = data
  },

  SET_BATTERIES (state, data) {
    state.batteries = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
