import { getTestsConsulta } from '@/api/ensayos'

const state = {
  tests: [],

  filtersDirty: false,

  filters: {
    batteryType: null,

    key: null,

    date: {
      min: null,
      max: null,
    },

    number: {
      min: null,
      max: null
    },
  },
}

const getters = {
  GET_FILTERS(state) {
    return state.filters
  },

  GET_TESTS(state) {
    return state.tests
  },
}

const actions = {
  async AC_GET_TESTS({ commit }) {
    await getTestsConsulta(state.filters).then(response => {
      const { data } = response
      commit('SET_TESTS', data.data.tests)
    })
  },

  AC_INIT_FILTERS({ state, commit }) {
    if (state.filtersDirty) {
      return
    }

    let date = new Date().toISOString().substr(0, 10)

    let data = {
      batteryType: null,

      key: null,

      date: {
        min: null,
        max: date,
      },

      number: {
        min: null,
        max: null,
      },
    }

    commit('SET_FILTERS', data)
    commit('SET_FILTERS_DIRTY', true)
  },

  AC_SET_FILTERS({ commit }, data) {
    commit('SET_FILTERS', data)
  },
}

const mutations = {
  SET_FILTERS(state, data) {
    state.filters = data
  },

  SET_FILTERS_DIRTY(state, data) {
    state.filtersDirty = data
  },

  SET_TESTS(state, data) {
    state.tests = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
