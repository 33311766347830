import { getLaboratories } from '@/api/laboratories'

const state = {
  laboratories: [],
}

const getters = {
  GET_LABORATORIES (state) {
    return state.laboratories
  },
}

const actions = {
  async AC_GET_LABORATORIES({ commit }) {
    await getLaboratories()
      .then(response => {
        const { data } = response
        commit('SET_LABORATORIES', data.data.laboratories)
      })
  },
}

const mutations = {
  SET_LABORATORIES (state, data) {
    state.laboratories = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
