import httpClient from './httpClient'
import getTestsQuery from '@/graphql/queries/tests/list.gql'
import getTestsConsultaQuery from '@/graphql/queries/tests/listConsulta.gql'
import { print } from 'graphql/language/printer';
import _ from "lodash"

const END_POINT = process.env.VUE_APP_GRAPHQL_HTTP
const API_END_POINT = '/tests'

const getTests = (queryParams) => {
  const { number, date } = queryParams

  let variableParams = {
    number: [{}],
    date: [{}],
  }

  if (!_.isNil(number.min) && !_.isNil(number.max)) {
    variableParams.number[0] = {between: `${number.min}..${number.max}`}
  } else if (!_.isNil(number.min)) {
    variableParams.number[0] = {gte: `${number.min}`}
  } else if (!_.isNil(number.max)) {
    variableParams.number[0] = {lte: `${number.max}`}
  }

  if (!_.isNil(date.min) && !_.isNil(date.max)) {
    variableParams.date[0] = {after: `${date.min}`, before: `${date.max}`}
  } else if (!_.isNil(date.min)) {
    variableParams.date[0] = {after: `${date.min}`}
  } else if (!_.isNil(date.max)) {
    variableParams.date[0] = {before: `${date.max}`}
  }

  return httpClient.post(END_POINT, {
    query: print(getTestsQuery),
    variables: {
      ...variableParams
    }
  })
}

const getTestsConsulta = (queryParams) => {
  const { batteryType, key, date, number } = queryParams

  let variableParams = {
    date: [{}],
    number: [{}],
  }

  if (!_.isNil(batteryType) && _.isObject(batteryType)) {
    variableParams.batteryType = `${batteryType.reference}`
  }

  if (!_.isNil(key) && _.isObject(key)) {
    variableParams.key = `${key.reference}`
  }

  if (!_.isNil(date.min) && !_.isNil(date.max)) {
    variableParams.date[0] = {after: `${date.min}`, before: `${date.max}`}
  } else if (!_.isNil(date.min)) {
    variableParams.date[0] = {after: `${date.min}`}
  } else if (!_.isNil(date.max)) {
    variableParams.date[0] = {before: `${date.max}`}
  }

  if (!_.isNil(number.min) && !_.isNil(number.max)) {
    variableParams.number[0] = {between: `${number.min}..${number.max}`}
  } else if (!_.isNil(number.min)) {
    variableParams.number[0] = {gte: `${number.min}`}
  } else if (!_.isNil(number.max)) {
    variableParams.number[0] = {lte: `${number.max}`}
  }

  return httpClient.post(END_POINT, {
    query: print(getTestsConsultaQuery),
    variables: {
      ...variableParams
    }
  })
}

const findById = async (id) => {
  return await httpClient.get(`${API_END_POINT}/${id}`)
}

const create = async (item) => {
  if (_.isNil(item.key)) {
    delete item.key
  }

  item.description = item.description || ''

  return await httpClient.post(API_END_POINT, item)
}

const update = async (item) => {
  let id = item.id || null

  if (id == null) {
    throw new Error('No es posible actualizar el ensayo, ID es nulo.')
  }

  item.description = item.description || ''

  let test = _.cloneDeep(item)
  delete test.batteries

  return await httpClient.put(`${API_END_POINT}/${id}`, test)
}

const remove = async (item) => {
  let id = item.id || null

  if (id == null) {
    throw new Error('No es posible eliminar el ensayo, ID es nulo.')
  }

  return await httpClient.delete(`${API_END_POINT}/${id}`)
}

const getFileOfPending = async (options) => {
  let params = {}
  let { programmedDate }  = options

  if (!_.isNil(programmedDate.min) && !_.isNil(programmedDate.max)) {
    params = { 'batteries.programmedDate[after]': programmedDate.min, 'batteries.programmedDate[before]': programmedDate.max }
  }else if (!_.isNil(programmedDate.min)) {
    params = { 'batteries.programmedDate[after]': programmedDate.min }
  } else if (!_.isNil(programmedDate.max)) {
    params = { 'batteries.programmedDate[before]': programmedDate.max }
  }

  return await httpClient.get(`${API_END_POINT}/pending-pdf`, { params })
}

export {
  getTests,
  getTestsConsulta,
  findById,
  create,
  update,
  remove,
  getFileOfPending,
}
