import httpClient from './httpClient'
import getKeysQuery from '@/graphql/queries/keys/list.gql'
import { print } from 'graphql/language/printer'

const END_POINT = process.env.VUE_APP_GRAPHQL_HTTP
const API_END_POINT = '/keys'

const getDefault = async (bool) => await httpClient.get(API_END_POINT, { params: { isDefault: bool, pagination: false} })

const getKeys = () => httpClient.post(END_POINT, { query: print(getKeysQuery) })

const findById = async (id) => await httpClient.get(`${API_END_POINT}/${id}`)

const create = async (item) => await httpClient.post(API_END_POINT, item)

const update = async (item) => {
  let id = item.id || null

  if (null === id) {
    throw new Error('No es posible actualizar la clave, ID es nulo.')
  }

  return await httpClient.put(`${API_END_POINT}/${id}`, item)
}

const remove = async (item) => {
  let id  = item.id || null

  if (null === id) {
    throw new Error('No es posible eliminar la clave, ID es nulo.')
  }

  return await httpClient.delete(`${API_END_POINT}/${id}`)
}

const getKeysForCombo = async () => await httpClient.get(API_END_POINT, { params: { pagination: false } })

export {
  getKeys,
  findById,
  create,
  update,
  remove,
  getKeysForCombo,
  getDefault,
}
