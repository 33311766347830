import { getKeys } from '@/api/keys'

const state = {
  keys: [],
}

const getters = {
  GET_KEYS (state) {
    return state.keys
  },
}

const actions = {
  async AC_GET_KEYS({ commit }) {
    await getKeys()
      .then(response => {
        const { data } = response
        commit('SET_KEYS', data.data.keys)
      })
  },
}

const mutations = {
  SET_KEYS (state, data) {
    state.keys = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
