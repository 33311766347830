import httpClient from './httpClient'
import getRulesQuery from '@/graphql/queries/rules/list.gql'
import { print } from 'graphql/language/printer'

const END_POINT = process.env.VUE_APP_GRAPHQL_HTTP
const API_END_POINT = '/rules'

const getRules = () => httpClient.post(END_POINT, { query: print(getRulesQuery) })

const findById = async (id) => await httpClient.get(`${API_END_POINT}/${id}`)

const create = async (item) => {
  item.activeHours = parseFloat(item.activeHours)
  item.resistence = parseFloat(item.resistence)
  item.current = parseFloat(item.current)
  item.shearStress = parseFloat(item.shearStress)

  return await httpClient.post(API_END_POINT, item)
}

const update = async (item) => {
  let id = item.id || null

  if (null === id) {
    throw new Error('No es posible actualizar la norma, ID es nulo.')
  }

  item.activeHours = parseFloat(item.activeHours)
  item.resistence = parseFloat(item.resistence)
  item.current = parseFloat(item.current)
  item.shearStress = parseFloat(item.shearStress)

  return await httpClient.put(`${API_END_POINT}/${id}`, item)
}

const remove = async (item) => {
  let id  = item.id || null

  if (null === id) {
    throw new Error('No es posible eliminar la norma, ID es nulo.')
  }

  return await httpClient.delete(`${API_END_POINT}/${id}`)
}

const getRulesForCombo = () => httpClient.get(API_END_POINT, { params: { pagination: false } })

export {
  getRules,
  findById,
  create,
  update,
  remove,
  getRulesForCombo,
}
