import { getListOfChannels } from '@/api/batteries'

const state = {
  batteries: [],
  filtersDirty: false,
  filters: {
    channelType: null,
    channelState: null,
    startDate: {
      min: null,
      max: null,
    },
  },
}

const getters = {
  GET_FILTERS(state) {
    return state.filters
  },

  GET_BATTERIES(state) {
    return state.batteries
  },
}

const actions = {
  async AC_GET_BATTERIES_CHANNELS({ commit }) {
    await getListOfChannels(state.filters).then(response => {
      const { data } = response
      commit('SET_BATTERIES', data.data.channels)
    })
  },

  AC_INIT_FILTERS({ state, commit }) {
    if (!state.filtersDirty) {
      let startDate = new Date().toISOString().substr(0, 10)

      let data = {
        channelType: null,
        channelState: null,
        startDate: {
          min: null,
          max: startDate,
        },
      }

      commit('SET_FILTERS', data)
      commit('SET_FILTERS_DIRTY', true)
    }
  },

  AC_SET_FILTERS({ commit }, data) {
    commit('SET_FILTERS', data)
  },
}

const mutations = {
  SET_FILTERS(state, data) {
    state.filters = data
  },

  SET_FILTERS_DIRTY(state, data) {
    state.filtersDirty = data
  },

  SET_BATTERIES(state, data) {
    state.batteries = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
