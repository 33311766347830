import httpClient from './httpClient'
import getIntegrationsQuery from '@/graphql/queries/integrations/list.gql'
import { print } from 'graphql/language/printer'
import _ from 'lodash'

const END_POINT = process.env.VUE_APP_GRAPHQL_HTTP

const getIntegrations = (queryParams) => {
  const { date } = queryParams

  let variableParams = {
    createdAt: [{}],
  }

  if (!_.isNil(date.min) && !_.isNil(date.max)) {
    let max = new Date(date.max)
    max.setDate(max.getDate() + 1)

    variableParams.createdAt[0] = { after: `${date.min}`, before: `${max.getFullYear()}-${max.getMonth() + 1}-${max.getDate()}`, }
  } else if (!_.isNil(date.min)) {
    variableParams.createdAt[0] = { after: `${date.min}`, }
  } else if (!_.isNil(date.max)) {
    let max = new Date(date.max)
    max.setDate(max.getDate() + 1)

    variableParams.createdAt[0] = { before: `${max.getFullYear()}-${max.getMonth() + 1}-${max.getDate()}`, }
  }

  return httpClient.post(END_POINT, {
    query: print(getIntegrationsQuery),
    variables: { ...variableParams },
  })
}

const reread = (id) => {
  return httpClient.get(`/tasks/file/reread/${id}`)
}

export {
  getIntegrations,
  reread,
}
