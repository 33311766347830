import httpClient from './httpClient'
import getChannelTypesQuery from '@/graphql/queries/channelTypes/list.gql'
import { print } from 'graphql/language/printer'

const END_POINT = process.env.VUE_APP_GRAPHQL_HTTP
const API_END_POINT = '/channel_types'

const getChannelTypes = () => httpClient.post(END_POINT, { query: print(getChannelTypesQuery) })

const findById = async (id) => await httpClient.get(`${API_END_POINT}/${id}`)

const create = async (item) => {
  item.maxVoltage = parseFloat(item.maxVoltage)
  item.maxIntensity = parseFloat(item.maxIntensity)

  return await httpClient.post(API_END_POINT, item)
}

const update = async (item) => {
  let id = item.id || null

  if (null === id) {
    throw new Error('No es posible actualizar el tipo de canal, ID es nulo.')
  }

  item.maxVoltage = parseFloat(item.maxVoltage)
  item.maxIntensity = parseFloat(item.maxIntensity)

  return await httpClient.put(`${API_END_POINT}/${id}`, item)
}

const remove = async (item) => {
  let id  = item.id || null

  if (null === id) {
    throw new Error('No es posible eliminar el tipo de canal, ID es nulo.')
  }

  return await httpClient.delete(`${API_END_POINT}/${id}`)
}

const getChannelTypesForCombo = async () => await httpClient.get(API_END_POINT, { params: { pagination: false } })

export {
  getChannelTypes,
  findById,
  create,
  update,
  remove,
  getChannelTypesForCombo,
}
