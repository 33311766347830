import { getRules } from '@/api/rules'

const state = {
  rules: [],
}

const getters = {
  GET_RULES (state) {
    return state.rules
  },
}

const actions = {
  async AC_GET_RULES({ commit }) {
    await getRules()
      .then(response => {
        const { data } = response
        commit('SET_RULES', data.data.rules)
      })
  },
}

const mutations = {
  SET_RULES (state, data) {
    state.rules = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
