import httpClient from './httpClient'

const END_POINT = '/authentication_token'

const doLogin = (username, password) => httpClient.post(END_POINT, {
  username,
  password,
})

export {
  doLogin,
}
