/**
 *               .__
 *  ___  __ ____ |__| ______ ______
 *  \  \/ // __ \|  |/  ___//  ___/
 *   \   /\  ___/|  |\___ \ \___ \
 *    \_/  \___  >__/____  >____  >
 *             \/        \/     \/
 *
 *  Client for handle http requests in JS apps
 *  Uses Axios for the requests
 *  iban@veiss.com
 *
 */

import axios from 'axios'
import store from '../store/index'
import router from '../router/index'

/**
 * Create an axios instance
 *
 */

const httpClient = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    timeout: 100000,
});

const authInterceptor = async (config) => {
    let token = localStorage.getItem('token')

    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`
    }

    return config
}

/**
 * Use the interceptor
 *
 */
httpClient.interceptors.request.use(authInterceptor)

// Add a 401 response interceptor
httpClient.interceptors.response.use((response) => {
    return response;
}, (error) => {
    if (401 === error.response.status) {
        store.dispatch('Alert/ADD', 'La sesión ha caducado')
        router.push('/login')
    }

    return Promise.reject(error);
});


export default httpClient