import httpClient from './httpClient'
import getPendingQuery from '@/graphql/queries/batteries/pending.gql'
import getChannelsQuery from '@/graphql/queries/batteries/channels.gql'
import getBatteriesCapacidadQuery from '@/graphql/queries/batteries/capacidad.gql'
import { print } from 'graphql/language/printer';
import _ from "lodash"

const END_POINT = process.env.VUE_APP_GRAPHQL_HTTP
const API_END_POINT = '/batteries'

const getPending = (queryParams) => {
  const { programmedDate } = queryParams

  let variableParams = {
    exists: [{ startDate: false }],
    programmedDate: [{}]
  }

  if (!_.isNil(programmedDate.min) && !_.isNil(programmedDate.max)) {
    variableParams.programmedDate[0] = {after: `${programmedDate.min}`, before: `${programmedDate.max}`}
  } else if (!_.isNil(programmedDate.min)) {
    variableParams.programmedDate[0] = {after: `${programmedDate.min}`}
  } else if (!_.isNil(programmedDate.max)) {
    variableParams.programmedDate[0] = {before: `${programmedDate.max}`}
  }

  return httpClient.post(END_POINT, {
    query: print(getPendingQuery),
    variables: {
      ...variableParams
    }
  })
}

const getListOfChannels = (queryParams) => {
  const {channelType, channelState, startDate} = queryParams
  let variableParams = {
    batteries_startDate: [{}]
  }

  if (!_.isNil(startDate.min) && !_.isNil(startDate.max)) {
    variableParams.batteries_startDate[0] = {after: `${startDate.min}`, before: `${startDate.max}`}
  } else if (!_.isNil(startDate.min)) {
    variableParams.batteries_startDate[0] = {after: `${startDate.min}`}
  } else if (!_.isNil(startDate.max)) {
    variableParams.batteries_startDate[0] = {before: `${startDate.max}`}
  }

  if (!_.isNil(channelType)) {
    variableParams.channelType = String(channelType)
  }

  if (!_.isNil(channelState)) {
    variableParams.channelState = String(channelState)
  }

  return httpClient.post(END_POINT, {
    query: print(getChannelsQuery),
    variables: {
      ...variableParams
    },
  })
}

const getBatteriesCapacidad = (queryParams) => {
  const { batteryType, canceled, capacity, channel, channelState, conservation, key, date, dchgTime, number, rule  } = queryParams

  let variableParams = {
    capacity: [{}],
    date: [{}],
    dchgTime: [{}],
    test: [{}],
  }

  if (!_.isNil(capacity.min) && !_.isNil(capacity.max)) {
    variableParams.capacity[0] = {between: `${capacity.min}..${capacity.max}`}
  } else if (!_.isNil(capacity.min)) {
    variableParams.capacity[0] = {gte: `${capacity.min}`}
  } else if (!_.isNil(capacity.max)) {
    variableParams.capacity[0] = {lte: `${capacity.max}`}
  }

  if (!_.isNil(date.min) && !_.isNil(date.max)) {
    variableParams.date[0] = {after: `${date.min}`, before: `${date.max}`}
  } else if (!_.isNil(date.min)) {
    variableParams.date[0] = {after: `${date.min}`}
  } else if (!_.isNil(date.max)) {
    variableParams.date[0] = {before: `${date.max}`}
  }

  if (!_.isNil(dchgTime.min) && !_.isNil(dchgTime.max)) {
    variableParams.dchgTime[0] = {between: `${dchgTime.min}..${dchgTime.max}`}
  } else if (!_.isNil(dchgTime.min)) {
    variableParams.dchgTime[0] = {gte: `${dchgTime.min}`}
  } else if (!_.isNil(dchgTime.max)) {
    variableParams.dchgTime[0] = {lte: `${dchgTime.max}`}
  }

  if (!_.isNil(number.min) && !_.isNil(number.max)) {
    variableParams.test[0] = {between: `${number.min}..${number.max}`}
  } else if (!_.isNil(number.min)) {
    variableParams.test[0] = {gte: `${number.min}`}
  } else if (!_.isNil(number.max)) {
    variableParams.test[0] = {lte: `${number.max}`}
  }

  if (!_.isNil(batteryType) && _.isObject(batteryType)) {
    variableParams.batteryType = `${batteryType.id}`
  }

  if (!_.isNil(canceled)) {
    variableParams.canceled = canceled
  }

  if (!_.isNil(channel) && _.isObject(channel)) {
    variableParams.channel = `${channel.id}`
  }

  if (!_.isNil(channelState) && _.isObject(channelState)) {
    variableParams.channelState = `${channelState.id}`
  }

  if (!_.isNil(conservation) && _.isObject(conservation)) {
    variableParams.conservation = `${conservation.id}`
  }

  if (!_.isNil(key) && _.isObject(key)) {
    variableParams.key = `${key.id}`
  }

  if (!_.isNil(rule) && _.isObject(rule)) {
    variableParams.rule = `${rule.id}`
  }

  return httpClient.post(END_POINT, {
    query: print(getBatteriesCapacidadQuery),
    variables: {
      ...variableParams
    }
  })
}

const update = async (item) => {
  let id = item.id || null
  if (null === id) {
    throw new Error('No es posible actualizar la pila, ID es nulo')
  }

  item.channel = (item.channel && typeof item.channel.id === 'object') ? item.channel.id : item.channel
  item.conservation = (item.conservation && typeof item.conservation.id === 'object') ? item.conservation.id : item.conservation
  item.rule = (item.rule && typeof item.rule.id === 'object') ? item.rule.id : item.rule

  item.observations = item.observations || ''

  if (item.channel && !item.channel.id || !item.channel) {
    item.channel = null
  } else {
    delete item.channel.channelColor
  }

  if (item.conservation && !item.conservation.id || !item.conservation) {
    item.conservation = null
  }

  if (item.rule && !item.rule.id || !item.rule) {
    item.rule = null
  }

  return await httpClient.put(`${API_END_POINT}/${id}`, item)
}

const getFileOfCapacity = async (options, sortBy, sortDesc) => {
  const { batteryType, canceled, capacity, channel, channelState, conservation, key, date, dchgTime, number, rule  } = options

  let params = {
    capacity: [{}],
    date: [{}],
    dchgTime: [{}],
    test: [{}],
    sortBy: '',
    sortDesc: 0,
  }

  if (!_.isNil(capacity.min) && !_.isNil(capacity.max)) {
    params = { 'capacity[after]': capacity.min, 'capacity[before]': capacity.max }
  } else if (!_.isNil(capacity.min)) {
    params = { 'capacity[after]': capacity.min }
  } else if (!_.isNil(capacity.max)) {
    params = { 'capacity[before]': capacity.max }
  }

  if (!_.isNil(date.min) && !_.isNil(date.max)) {
    params = { 'date[after]': date.min, 'date[before]': date.max }
  } else if (!_.isNil(date.min)) {
    params = { 'date[after]': date.min }
  } else if (!_.isNil(date.max)) {
    params = { 'date[before]': date.max }
  }

  if (!_.isNil(dchgTime.min) && !_.isNil(dchgTime.max)) {
    params = { 'dchgTime[after]': dchgTime.min, 'dchgTime[before]': dchgTime.max }
  } else if (!_.isNil(dchgTime.min)) {
    params = { 'dchgTime[after]': dchgTime.min }
  } else if (!_.isNil(dchgTime.max)) {
    params = { 'dchgTime[before]': dchgTime.max }
  }

  if (!_.isNil(number.min) && !_.isNil(number.max)) {
    params = { 'number[after]': number.min, 'number[before]': number.max }
  } else if (!_.isNil(number.min)) {
    params = { 'number[after]': number.min }
  } else if (!_.isNil(number.max)) {
    params = { 'number[before]': number.max }
  }

  if (!_.isNil(batteryType) && _.isObject(batteryType)) {
    params.batteryType = `${batteryType.id}`
  }

  if (!_.isNil(canceled)) {
    params.canceled = canceled
  }

  if (!_.isNil(channel) && _.isObject(channel)) {
    params.channel = `${channel.id}`
  }

  if (!_.isNil(channelState) && _.isObject(channelState)) {
    params.channelState = `${channelState.id}`
  }

  if (!_.isNil(conservation) && _.isObject(conservation)) {
    params.conservation = `${conservation.id}`
  }

  if (!_.isNil(key) && _.isObject(key)) {
    params.key = `${key.id}`
  }

  if (!_.isNil(rule) && _.isObject(rule)) {
    params.rule = `${rule.id}`
  }

  params.sortBy = sortBy
  params.sortDesc = sortDesc ? 1 : 0

  return await httpClient.get(`${API_END_POINT}/capacity-pdf`, { params })
}

const getAccumulates = async (ids, filters) => {
  const { hAxis, vAxis } = filters

  return await httpClient.get(`${API_END_POINT}/grafico?ids=${ids}&hAxis=${hAxis}&vAxis=${vAxis.join(',')}`)
}

export {
  getPending,
  getListOfChannels,
  getBatteriesCapacidad,
  update,
  getFileOfCapacity,
  getAccumulates,
}
