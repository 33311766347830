import httpClient from './httpClient'
import getLaboratoriesQuery from '@/graphql/queries/laboratories/list.gql'
import { print } from 'graphql/language/printer'

const END_POINT = process.env.VUE_APP_GRAPHQL_HTTP
const API_END_POINT = '/laboratories'

const getDefault = async (bool) => await httpClient.get(API_END_POINT, { params: { isDefault: bool, pagination: false } })

const getLaboratories = () => httpClient.post(END_POINT, { query: print(getLaboratoriesQuery) })

const findById = async (id) => await httpClient.get(`${API_END_POINT}/${id}`)

const create = async (item) => await httpClient.post(API_END_POINT, item)

const update = async (item) => {
  let id = item.id || null

  if (null === id) {
    throw new Error('No es posible actualizar el laboratorio, ID es nulo.')
  }

  return await httpClient.put(`${API_END_POINT}/${id}`, item)
}

const launchIntegration = async () => await httpClient.get('tasks/file/read')

const remove = async (item) => {
  let id = item.id || null

  if (null === id) {
    throw new Error('No es posible eliminar el laboratorio, ID es nulo.')
  }

  return await httpClient.delete(`${API_END_POINT}/${id}`)
}

const getLaboratoriesForCombo = async () => await httpClient.get(API_END_POINT, { params: { pagination: false } })

export {
  getLaboratories,
  findById,
  create,
  update,
  launchIntegration,
  remove,
  getLaboratoriesForCombo,
  getDefault,
}
