import { getChannelTypes } from '@/api/channelTypes'

const state = {
  channelTypes: [],
}

const getters = {
  GET_CHANNEL_TYPES (state) {
    return state.channelTypes
  },
}

const actions = {
  async AC_GET_CHANNEL_TYPES({ commit }) {
    await getChannelTypes()
      .then(response => {
        const { data } = response
        commit('SET_CHANNEL_TYPES', data.data.channelTypes)
      })
  },
}

const mutations = {
  SET_CHANNEL_TYPES (state, data) {
    state.channelTypes = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
