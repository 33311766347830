const Escritorio = () => import(/* webpackChunkName: "escritorio" */ '@/views/Escritorio')

const Ensayos = () => import(/* webpackChunkName: "ensayos" */ '@/views/Ensayos/Ensayos')
const EnsayosPendientes = () => import(/* webpackChunkName: "ensayosPendientes" */ '@/views/Ensayos/EnsayosPendientes')
const EnsayosCanales = () => import(/* webpackChunkName: "ensayosCanales" */ '@/views/Ensayos/EnsayosCanales')
const EnsayosCreate = () => import(/* webpackChunkName: "ensayosCreate" */ '@/views/Ensayos/EnsayosCreate')
const EnsayosEdit = () => import(/* webpackChunkName: "ensayosEdit" */ '@/views/Ensayos/EnsayosEdit')

const Integraciones = () => import(/* webpackChunkName: "integraciones" */ '@/views/Integraciones/Integraciones')
const IntegracionesErrores = () => import(/* webpackChunkName: "integraciones-errores" */ '@/views/Integraciones/IntegracionesErrores')

const ConsultasGrafico = () => import(/* webpackChunkName: "consultas-grafico" */ '@/views/Consultas/Grafico')
const ConsultasEnsayos = () => import(/* webpackChunkName: "consultas-ensayos" */ '@/views/Consultas/Ensayos')
const ConsultasCapacidad = () => import(/* webpackChunkName: "consultas-capacidad" */ '@/views/Consultas/Capacidad')

const Canales = () => import(/* webpackChunkName: "canales" */ '@/views/Canales/Canales')
const CanalesCreate = () => import(/* webpackChunkName: "canalesCreate" */ '@/views/Canales/CanalesCreate')
const CanalesEdit = () => import(/* webpackChunkName: "canalesEdit" */ '@/views/Canales/CanalesEdit')

const Claves = () => import(/* webpackChunkName: "claves" */ '@/views/Claves/Claves')
const ClavesCreate = () => import(/* webpackChunkName: "clavesCreate" */ '@/views/Claves/ClavesCreate')
const ClavesEdit = () => import(/* webpackChunkName: "clavesEdit" */ '@/views/Claves/ClavesEdit')

const Conservaciones = () => import(/* webpackChunkName: "conservaciones" */ '@/views/Conservaciones/Conservaciones')
const ConservacionesCreate = () => import(/* webpackChunkName: "conservacionesCreate" */ '@/views/Conservaciones/ConservacionesCreate')
const ConservacionesEdit = () => import(/* webpackChunkName: "conservacionesEdit" */ '@/views/Conservaciones/ConservacionesEdit')

const Laboratorios = () => import(/* webpackChunkName: "laboratorios" */ '@/views/Laboratorios/Laboratorios')
const LaboratoriosCreate = () => import(/* webpackChunkName: "laboratoriosCreate" */ '@/views/Laboratorios/LaboratoriosCreate')
const LaboratoriosEdit = () => import(/* webpackChunkName: "laboratoriosEdit" */ '@/views/Laboratorios/LaboratoriosEdit')

const Normas = () => import(/* webpackChunkName: "normas" */ '@/views/Normas/Normas')
const NormasCreate = () => import(/* webpackChunkName: "normasCreate" */ '@/views/Normas/NormasCreate')
const NormasEdit = () => import(/* webpackChunkName: "normasEdit" */ '@/views/Normas/NormasEdit')

const TiposCanal = () => import(/* webpackChunkName: "tiposCanal" */ '@/views/TiposCanal/TiposCanal')
const TiposCanalCreate = () => import(/* webpackChunkName: "tiposCanalCreate" */ '@/views/TiposCanal/TiposCanalCreate')
const TiposCanalEdit = () => import(/* webpackChunkName: "tiposCanalEdit" */ '@/views/TiposCanal/TiposCanalEdit')

const TiposIncidencia = () => import(/* webpackChunkName: "tiposIncidencia" */ '@/views/TiposIncidencia/TiposIncidencia')
const TiposIncidenciaCreate = () => import(/* webpackChunkName: "tiposIncidenciaCreate" */ '@/views/TiposIncidencia/TiposIncidenciaCreate')
const TiposIncidenciaEdit = () => import(/* webpackChunkName: "tiposIncidenciaEdit" */ '@/views/TiposIncidencia/TiposIncidenciaEdit')

const TiposPila = () => import(/* webpackChunkName: "tiposPila" */ '@/views/TiposPila/TiposPila')
const TiposPilaCreate = () => import(/* webpackChunkName: "tiposPilaCreate" */ '@/views/TiposPila/TiposPilaCreate')
const TiposPilaEdit = () => import(/* webpackChunkName: "tiposPilaEdit" */ '@/views/TiposPila/TiposPilaEdit')

const ValoresDefecto = () => import(/* webpackChunkName: "valoresDefecto" */ '@/views/ValoresDefecto/ValoresDefecto')

const Usuarios = () => import(/* webpackChunkName: "usuarios" */ '@/views/Usuarios/Usuarios')
const UsuariosCreate = () => import(/* webpackChunkName: "usuariosCreate" */ '@/views/Usuarios/UsuariosCreate')
const UsuariosEdit = () => import(/* webpackChunkName: "usuariosEdit" */ '@/views/Usuarios/UsuariosEdit')

const Login = () => import(/* webpackChunkName: "login" */ '@/views/Login')
const Logout = () => import(/* webpackChunkName: "logout" */ '@/views/Logout')
const NotFound = () => import(/* webpackChunkName: "notfound" */ '@/views/NotFound')

const routes = [
  { path: '/', name: 'escritorio', component: Escritorio, meta: { requiresAuth: true } },

  { path: '/ensayos', name: 'ensayos', component: Ensayos, meta: { requiresAuth: true } },
  { path: '/ensayos/listado-ensayos-pendientes', name: 'ensayosPendingList', component: EnsayosPendientes, meta: { requiresAuth: true } },
  { path: '/ensayos/listado-canales', name: 'ensayosChannelList', component: EnsayosCanales, meta: { requiresAuth: true } },
  { path: '/ensayos/nuevo', name: 'ensayosCreate', component: EnsayosCreate, meta: { requiresAuth: true } },
  { path: '/ensayos/:id/modificar', name: 'ensayosEdit', component: EnsayosEdit, meta: { requiresAuth: true } },

  { path: '/integraciones', name: 'integraciones', component: Integraciones, meta: { requiresAuth: true } },
  { path: '/integraciones-errores', name: 'integracionesErrores', component: IntegracionesErrores, meta: { requiresAuth: true } },

  { path: '/grafico-de-resultados', name: 'graficoResultados', component: ConsultasGrafico, meta: { requiresAuth: true } },
  { path: '/listado-ensayos', name: 'listadoEnsayos', component: ConsultasEnsayos, meta: { requiresAuth: true} },
  { path: '/resultados-capacidad', name: 'resultadosCapacidad', component: ConsultasCapacidad, meta: { requiresAuth: true} },

  { path: '/canales', name: 'canales', component: Canales, meta: { requiresAuth: true } },
  { path: '/canales/nuevo', name: 'canalesCreate', component: CanalesCreate, meta: { requiresAuth: true } },
  { path: '/canales/:id', name: 'canalesEdit', component: CanalesEdit, meta: { requiresAuth: true } },

  { path: '/claves', name: 'claves', component: Claves, meta: { requiresAuth: true } },
  { path: '/claves/nueva', name: 'clavesCreate', component: ClavesCreate, meta: { requiresAuth: true } },
  { path: '/claves/:id', name: 'clavesEdit', component: ClavesEdit, meta: { requiresAuth: true } },

  { path: '/conservaciones', name: 'conservaciones', component: Conservaciones, meta: { requiresAuth: true } },
  { path: '/conservaciones/nueva', name: 'conservacionesCreate', component: ConservacionesCreate, meta: { requiresAuth: true } },
  { path: '/conservaciones/:id', name: 'conservacionesEdit', component: ConservacionesEdit, meta: { requiresAuth: true } },

  { path: '/laboratorios', name: 'laboratorios', component: Laboratorios, meta: { requiresAuth: true } },
  { path: '/laboratorios/nuevo', name: 'laboratoriosCreate', component: LaboratoriosCreate, meta: { requiresAuth: true } },
  { path: '/laboratorios/:id', name: 'laboratoriosEdit', component: LaboratoriosEdit, meta: { requiresAuth: true } },

  { path: '/normas', name: 'normas', component: Normas, meta: { requiresAuth: true } },
  { path: '/normas/nueva', name: 'normasCreate', component: NormasCreate, meta: { requiresAuth: true } },
  { path: '/normas/:id', name: 'normasEdit', component: NormasEdit, meta: { requiresAuth: true } },

  { path: '/tipos-canal', name: 'tiposCanal', component: TiposCanal, meta: { requiresAuth: true } },
  { path: '/tipos-canal/nueva', name: 'tiposCanalCreate', component: TiposCanalCreate, meta: { requiresAuth: true } },
  { path: '/tipos-canal/:id', name: 'tiposCanalEdit', component: TiposCanalEdit, meta: { requiresAuth: true } },

  { path: '/tipos-incidencia', name: 'tiposIncidencia', component: TiposIncidencia, meta: { requiresAuth: true } },
  { path: '/tipos-incidencia/nueva', name: 'tiposIncidenciaCreate', component: TiposIncidenciaCreate, meta: { requiresAuth: true } },
  { path: '/tipos-incidencia/:id', name: 'tiposIncidenciaEdit', component: TiposIncidenciaEdit, meta: { requiresAuth: true } },

  { path: '/tipos-pila', name: 'tiposPila', component: TiposPila, meta: { requiresAuth: true } },
  { path: '/tipos-pila/nueva', name: 'tiposPilaCreate', component: TiposPilaCreate, meta: { requiresAuth: true } },
  { path: '/tipos-pila/:id', name: 'tiposPilaEdit', component: TiposPilaEdit, meta: { requiresAuth: true } },

  { path: '/valores-por-defecto', name: 'valoresDefecto', component: ValoresDefecto, meta: { requiresAuth: true } },

  { path: '/usuarios', name: 'usuarios', component: Usuarios, meta: { requiresAuth: true } },
  { path: '/usuarios/nuevo', name: 'usuariosCreate', component: UsuariosCreate, meta: { requiresAuth: true } },
  { path: '/usuarios/:id', name: 'usuariosEdit', component: UsuariosEdit, meta: { requiresAuth: true } },

  { path: '/login', name: 'login', component: Login, meta: { requiresAuth: false } },
  { path: '/logout', name: 'logout', component: Logout },
  { path: '*', name: 'notFound', component: NotFound }
]

export default routes
