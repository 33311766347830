import { getChannels } from '@/api/channels'

const state = {
  channels: [],
}

const getters = {
  GET_CHANNELS (state) {
    return state.channels
  },
}

const actions = {
  async AC_GET_CHANNELS({ commit }) {
    await getChannels()
      .then(response => {
        const { data } = response
        commit('SET_CHANNELS', data.data.channels)
      })
  },
}

const mutations = {
  SET_CHANNELS (state, data) {
    state.channels = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
