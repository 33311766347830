import { getIntegrationsErrors } from '@/api/integrationsErrors'

const state = {
  integrationsErrors: [],
  filtersDirty: false,
  filters: {
    date: {
      min: null,
      max: null,
    },
  },
}

const getters = {
  GET_FILTERS(state) {
    return state.filters
  },

  GET_INTEGRATIONS_ERRORS(state) {
    return state.integrationsErrors
  },
}

const actions = {
  async AC_GET_INTEGRATIONS_ERRORS({ commit }) {
    await getIntegrationsErrors(state.filters)
      .then(response => {
        const { data } = response
        commit('SET_INTEGRATIONS_ERRORS', data.data.incidences)
      })
  },

  AC_INIT_FILTERS({ commit, state }) {
    if (!state.filtersDirty) {
      let date = new Date().toISOString().substr(0, 10)

      let filters = {
        date: {
          min: date,
          max: null,
        },
      }

      commit('SET_FILTERS', filters)
      commit('SET_FILTERS_DIRTY', true)
    }
  },

  AC_SET_FILTERS({ commit }, data) {
    commit('SET_FILTERS', data)
  },
}

const mutations = {
  SET_FILTERS(state, data) {
    state.filters = data
  },

  SET_FILTERS_DIRTY(state, data) {
    state.filtersDirty = data
  },

  SET_INTEGRATIONS_ERRORS(state, data) {
    state.integrationsErrors = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
