import { getTests } from '@/api/ensayos'

const state = {
  tests: [],
  testsAll: [],
  filtersDirty: false,
  filters: {
    number: {
      min: null,
      max: null
    },
    date: {
      min: null,
      max: null,
    },
  },
}

const getters = {
  GET_FILTERS(state) {
    return state.filters
  },

  GET_TESTS(state) {
    return state.tests.filter(test => 3 > test.testState._id)
  },

  GET_ALL_TESTS(state) {
    return state.testsAll
  },
}

const actions = {
  async AC_GET_TESTS({ commit }) {
    await getTests(state.filters).then(response => {
      const { data } = response;
      commit('SET_TESTS', data.data.tests)
    });
  },

  async AC_GET_ALL_TESTS({ commit }) {
    await getTests({
      number: {
        min: null,
        max: null
      },
      date: {
        min: null,
        max: null,
      },
    })
    .then(response => {
      const { data } = response
      commit('SET_ALL_TESTS', data.data.tests)
    })
  },

  AC_INIT_FILTERS({ state, commit }) {
    if (!state.filtersDirty) {
      let date = new Date().toISOString().substr(0, 10)

      let data = {
        number: {
          min: null,
          max: null
        },
        date: {
          min: null,
          max: date,
        }
      }

      commit('SET_FILTERS', data)
      commit('SET_FILTERS_DIRTY', true)
    }
  },

  AC_SET_FILTERS({commit}, data) {
    commit('SET_FILTERS', data)
  },
}

const mutations = {
  SET_FILTERS(state, data) {
    state.filters = data
  },

  SET_FILTERS_DIRTY(state, data) {
    state.filtersDirty = data
  },

  SET_TESTS(state, data) {
    state.tests = data
  },

  SET_ALL_TESTS(state, data) {
    state.testsAll = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
