const state = {
  snack: {
    active: false,
    timeout: 2000,
    color: '',
    text: ''
  }
}
  
const getters = {
  GET_SNACK (state) {
    return state.snack
  },
}
  
const actions = {
  INITIALIZE_SNACK ({state, commit}) {
    commit('SET_SNACK_OPTIONS', {
      active: state.snack.active,
      timeout: state.snack.timeout,
      color: state.snack.color,
      text: state.snack.text
    })
  },
  RESET_SNACK ({commit}) {
    commit('SET_SNACK_OPTIONS', {
      active: false,
      color: '',
      timeout: 2000,
      text: ''
    })
  },

  TRIGGER_SNACK_SUCCESS ({commit},text) {
    commit('SET_SNACK_OPTIONS', {
      active: true,
      color: 'success',
      text: text
    })
  },
 
  TRIGGER_SNACK_ERROR ({commit},text) {
    commit('SET_SNACK_OPTIONS', {
      active: true,
      color: 'error',
      text: text
    })
  },
  
}
  
const mutations = {    
  SET_SNACK_OPTIONS (state, options) {
    
    state.snack.active = options.active
    state.snack.color = options.color
    state.snack.text = options.text
    
    if (typeof options.timeout !== "undefined") {
      state.snack.timeout = options.timeout  
    }

  }
}
  
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}