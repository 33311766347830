import { getIncidenceTypes } from '@/api/incidenceTypes'

const state = {
  incidenceTypes: [],
}

const getters = {
  GET_INCIDENCE_TYPES (state) {
    return state.incidenceTypes
  },
}

const actions = {
  async AC_GET_INCIDENCE_TYPES({ commit }) {
    await getIncidenceTypes()
      .then(response => {
        const { data } = response
        commit('SET_INCIDENCE_TYPES', data.data.fileErrors)
      })
  },
}

const mutations = {
  SET_INCIDENCE_TYPES (state, data) {
    state.incidenceTypes = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
