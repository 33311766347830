class DateUtils {

  constructor( value ) {

    if (value) {

      let valueArr = value.split('T')
      let dateData = valueArr[0].split('-')
      let year = dateData[0]
      let month = parseInt(dateData[1] - 1)
      let day = dateData[2]

      this.valueArr = valueArr
      this.date = new Date(Date.UTC(year, month, day, 12, 0, 0));

    } else {

      let now = Date.now();
      this.date = new Date(now)
      let [day, month, year] = this.getDateValues()
      this.date = new Date(Date.UTC(year,  parseInt(month -1), day, 12, 0, 0))

    }

  }

  formatDateDDMMYYYY() {

    let [day, month, year] = this.getDateValues()
    return `${day}/${month}/${year}`
  }

  formatDateHHII() {
    let [hour, minutes] = this.getTimeValues()

    if (hour != '' && minutes != '' ) {
      return `${hour}:${minutes}`
    }

    return ''
  }

  formatDateYYYYMMDD() {

    let [day, month, year] = this.getDateValues()
    return `${year}-${month}-${day}`
  }

  formatDateDDMMYYYYToFilename() {

    let [day, month, year] = this.getDateValues()
    return `${day}${month}${year}`
  }

  getDateValues() {

    let day = this.date.getDate()
    let month = this.date.getMonth() + 1
    let year = this.date.getFullYear()

    if (day < 10) {
        day = `0${day}`
    }

    if(month < 10) {
        month = `0${month}`
    }

    return [day, month, year]
  }

  getTimeValues() {

    let hour = ''
    let minutes = ''

    if (null != this.valueArr) {
      let hourData = this.valueArr[1].split(':')
      hour = hourData[0]
      minutes = hourData[1]
    }

    return [hour, minutes]
  }

}

export default DateUtils;