const state = {
  alert: ''
}

const actions = {
  ADD({ commit }, alert) {
    return new Promise((resolve) => {
      commit('set_alert', alert)

      resolve(alert)
    })
  },

  CLEAR({ commit }) {
    return new Promise((resolve) => {
      commit('set_alert', '')

      resolve('')
    })
  }
}

const mutations = {
  set_alert(state, newAlert) {
    state.alert = newAlert
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}