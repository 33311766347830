import { getBatteriesCapacidad } from '@/api/batteries'

const state = {
  batteries: [],
  filtersDirty: false,
  filters: {
    batteryType: null,
    canceled: null,
    channel: null,
    channelState: null,
    conservation: null,
    key: null,
    rule: null,

    capacity: {
      max: null,
      min: null,
    },

    date: {
      max: null,
      min: null,
    },

    dchgTime: {
      max: null,
      min: null,
    },

    number: {
      max: null,
      min: null,
    },
  },
}

const getters = {
  GET_FILTERS(state) {
    return state.filters
  },

  GET_BATTERIES(state) {
    return state.batteries
  },
}

const actions = {
  async AC_GET_BATTERIES({ commit }) {
    await getBatteriesCapacidad(state.filters)
      .then(response => {
        const { data } = response
        commit('SET_BATTERIES', data.data.batteries)
      })
  },

  AC_INIT_FILTERS({ state, commit }) {
    if (state.filtersDirty) {
      return
    }

    let date = new Date().toISOString().substr(0, 10)

    let data = {
      batteryType: null,
      canceled: null,
      channel: null,
      channelState: null,
      conservation: null,
      key: null,
      rule: null,

      capacity: {
        max: null,
        min: null,
      },

      date: {
        max: date,
        min: null,
      },

      dchgTime: {
        max: null,
        min: null,
      },

      number: {
        max: null,
        min: null,
      },
    }

    commit('SET_FILTERS', data)
    commit('SET_FILTERS_DIRTY', true)
  },

  AC_SET_FILTERS({ commit }, data) {
    commit('SET_FILTERS', data)
  },
}

const mutations = {
  SET_FILTERS(state, data) {
    state.filters = data
  },

  SET_FILTERS_DIRTY(state, data) {
    state.filtersDirty = data
  },

  SET_BATTERIES(state, data) {
    state.batteries = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
