import httpClient from './httpClient'
import getConservationsQuery from '@/graphql/queries/conservations/list.gql'
import { print } from 'graphql/language/printer'

const END_POINT = process.env.VUE_APP_GRAPHQL_HTTP
const API_END_POINT = '/conservations'

const getConservations = () => httpClient.post(END_POINT, { query: print(getConservationsQuery) })

const findById = async (id) => await httpClient.get(`${API_END_POINT}/${id}`)

const create = async (item) => {
  item.humidity = parseInt(item.humidity)
  item.timePeriod = parseInt(item.timePeriod)
  item.temperature = parseInt(item.temperature)

  return await httpClient.post(API_END_POINT, item)
}

const update = async (item) => {
  let id = item.id || null

  if (null === id) {
    throw new Error('No es posible actualizar la conservación, ID es nulo.')
  }

  item.humidity = parseInt(item.humidity)
  item.timePeriod = parseInt(item.timePeriod)
  item.temperature = parseInt(item.temperature)

  return await httpClient.put(`${API_END_POINT}/${id}`, item)
}

const remove = async (item) => {
  let id = item.id || null

  if (null === id) {
    throw new Error('No es posible eliminar la conservación, ID es nulo.')
  }

  return await httpClient.delete(`${API_END_POINT}/${id}`)
}

const getConservationsForCombo = async () => await httpClient.get(API_END_POINT, { params: { pagination: false } })

export {
  getConservations,
  findById,
  create,
  update,
  remove,
  getConservationsForCombo,
}
