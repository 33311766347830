import { getUsers } from '@/api/usuarios'

const state = {
  users: [],
}

const getters = {
  GET_USERS (state) {
    return state.users
  },
}

const actions = {
  async AC_GET_USERS({ commit }) {
    await getUsers()
    .then(response => {
      const { data } = response
      commit('SET_USERS', data.data.users)
    })
  },
}

const mutations = {
  SET_USERS (state, data) {
    state.users = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
