import Vue from 'vue'
import DateUtils from '@/libraries/DateUtils'
import _ from 'lodash'

Vue.filter('formatDateDDMMYYYY', (value) => {
  if (_.isNil(value)) {
    return value
  }

  let dateUtils = new DateUtils(value)

  return dateUtils.formatDateDDMMYYYY()
})

Vue.filter('formatDateHHII', (value) => {
  if (_.isNil(value)) {
    return value
  }

  let dateUtils = new DateUtils(value)

  return dateUtils.formatDateHHII()
})

Vue.filter('truncate', (value, length, concat) => {
  if (_.isNil(value) || _.isEmpty(value)) {
    return value
  }

  if (value.length <= length) {
    return value
  }

  return value.slice(0, length).concat(concat)
})

Vue.filter('formatFloat', (value) => {
  if (!_.isNumber(value)) {
    return value
  }

  return value.toLocaleString('es-ES', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: 'currency',
    currency: 'EUR'
  })
})

Vue.filter('boolToString', (value) => value ? 'Sí' : 'No')

Vue.filter('testStateToString', value => {
  switch (value._id) {
    case 1:
      return 'No'
    case 2:
      return 'Si'
    case 3:
      return 'Borrado'
    default:
      return ''
  }
})
