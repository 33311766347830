import httpClient from './httpClient'
import getIncidenceTypesQuery from '@/graphql/queries/incidenceTypes/list.gql'
import { print } from 'graphql/language/printer'

const END_POINT = process.env.VUE_APP_GRAPHQL_HTTP
const API_END_POINT = '/file_errors'

const getIncidenceTypes = () => httpClient.post(END_POINT, { query: print(getIncidenceTypesQuery) })

const findById = async (id) => await httpClient.get(`${API_END_POINT}/${id}`)

const create = async (item) => await httpClient.post(API_END_POINT, item)

const update = async (item) => {
  let id = item.id || null

  if (null === id) {
    throw new Error('No es posible actualizar el tipo de incidencia, ID es nulo.')
  }

  return await httpClient.put(`${API_END_POINT}/${id}`, item)
}

const remove = async (item) => {
  let id  = item.id || null

  if (null === id) {
    throw new Error('No es posible eliminar el tipo de incidencia, ID es nulo.')
  }

  return await httpClient.delete(`${API_END_POINT}/${id}`)
}

export {
  getIncidenceTypes,
  findById,
  create,
  update,
  remove,
}
