class JwtUtils {
    
    constructor() {}
    
    /**
     * Obtiene el payload de un JWT
     * 
     * @param {String} token
     * @returns Object
     */
    getJwtTokenInfo(token) {
      let base64Url = token.split('.')[1];
      let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload);
    }

}

export default JwtUtils;