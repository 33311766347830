import { getIntegrations } from '@/api/integrations'

const state = {
  integrations: [],
  filtersDirty: false,
  filters: {
    date: {
      min: null,
      max: null,
    },
  },

}

const getters = {
  GET_FILTERS(state) {
    return state.filters
  },

  GET_INTEGRATIONS(state) {
    return state.integrations
  },
}

const actions = {
  async AC_GET_INTEGRATIONS({ commit }) {
    await getIntegrations(state.filters)
      .then(response => {
        const { data } = response
        commit('SET_INTEGRATIONS', data.data.files)
      })
  },

  AC_INIT_FILTERS({ commit, state }) {
    if (!state.filtersDirty) {
      let date = new Date().toISOString().substr(0, 10)

      let filters = {
        date: {
          min: null,
          max: date,
        },
      }

      commit('SET_FILTERS', filters)
      commit('SET_FILTERS_DIRTY', true)
    }
  },

  AC_SET_FILTERS({ commit }, data) {
    commit('SET_FILTERS', data)
  },
}

const mutations = {
  SET_FILTERS(state, data) {
    state.filters = data
  },

  SET_FILTERS_DIRTY(state, data) {
    state.filtersDirty = data
  },

  SET_INTEGRATIONS(state, data) {
    state.integrations = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
