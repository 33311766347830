import httpClient from './httpClient'
import getChannelsQuery from '@/graphql/queries/channels/list.gql'
import getComboChannelsQuery from '@/graphql/queries/channels/comboList.gql'
import { print } from 'graphql/language/printer'
import _ from "lodash"

const END_POINT = process.env.VUE_APP_GRAPHQL_HTTP
const API_END_POINT = '/channels'

const getChannels = () => httpClient.post(END_POINT, { query: print(getChannelsQuery) })

const findById = async (id) => await httpClient.get(`${API_END_POINT}/${id}`)

const create = async (item) => await httpClient.post(API_END_POINT, item)

const update = async (item) => {
  let id = item.id || null

  if(null === id) {
    throw new Error('No es posible actualizar el canal, ID es nulo.')
  }

  return await httpClient.put(`${API_END_POINT}/${id}`, item)
}

const remove = async (item) => {
  let id = item.id || null

  if (null === id) {
    throw new Error('No es posible eliminar el canal, ID es nulo.')
  }

  return await httpClient.delete(`${API_END_POINT}/${id}`)
}

const getChannelsForCombo = () => httpClient.post(END_POINT, { query: print(getComboChannelsQuery) })

const getFileOfChannels = async (options) => {
  let params = {}
  const { startDate, ...rest } = options

  // filter channelType
  if (!_.isNil(rest.channelType)) {
    params.channelType = rest.channelType
  }

  // filter channelState
  if (!_.isNil(rest.channelState)) {
    params.channelState = rest.channelState
  }

  // filter startDate
  if (!_.isNil(startDate.min) && !_.isNil(startDate.max)) {
    params["batteries.startDate[after]"] = startDate.min
    params["batteries.startDate[before]"] = startDate.max
  }else if (!_.isNil(startDate.min)) {
    params["batteries.startDate[after]"] = startDate.min
  } else if (!_.isNil(startDate.max)) {
    params["batteries.startDate[before]"] = startDate.max
  }

  return await httpClient.get(`${API_END_POINT}/pdf`, { params })
}

export {
  getChannels,
  findById,
  create,
  update,
  remove,
  getChannelsForCombo,
  getFileOfChannels
}
