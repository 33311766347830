import { getAccumulates } from '@/api/batteries'

const state = {
  batteries: [],
  colors: [],
  table: [],
  filters: {
    hAxis: '',
    vAxis: [],
  },
  ids: [],
}

const getters = {
  GET_BATTERIES(state) {
    return state.batteries
  },

  GET_COLORS(state) {
    return state.colors
  },

  GET_TABLE(state) {
    return state.table
  },

  GET_FILTERS(state) {
    return state.filters
  },

  GET_IDS(state) {
    return state.ids
  },
}

const actions = {
  async AC_GET_VALUES({ commit }) {
    let { filters, ids } = state

    await getAccumulates(ids.join(','), filters)
      .then(response => {
        const { data } = response

        commit('SET_BATTERIES', data.output.batteries)
        commit('SET_COLORS', data.output.colors)
        commit('SET_TABLE', data.output.table)
      })
  },

  AC_SET_FILTERS({ commit }, data) {
    commit('SET_FILTERS', data)
  },

  AC_SET_IDS({ commit }, data) {
    commit('SET_IDS', data)
  },
}

const mutations = {
  SET_BATTERIES(state, data) {
    state.batteries = data
  },

  SET_COLORS(state, data) {
    state.colors = data
  },

  SET_TABLE(state, data) {
    state.table = data
  },

  SET_FILTERS(state, data) {
    state.filters = data
  },

  SET_IDS(state, data) {
    state.ids = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
