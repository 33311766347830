import httpClient from './httpClient'
import getIntegrationsErrorsQuery from '@/graphql/queries/integrationsErrors/list.gql'
import { print } from 'graphql/language/printer'
import _ from 'lodash'

const END_POINT = process.env.VUE_APP_GRAPHQL_HTTP
const API_END_POINT = '/incidences'

const getIntegrationsErrors = (queryParams) => {
  const { date } = queryParams

  let variableparams = {
    date: [{}],
  }

  if (!_.isNil(date.min) && !_.isNil(date.max)) {
    let max = new Date(date.max)
    max.setDate(max.getDate() + 1)

    variableparams.date[0] = { after: `${date.min}`, before: `${max.getFullYear()}-${max.getMonth() + 1}-${max.getDate()}`, }
  } else if (!_.isNil(date.min)) {
    variableparams.date[0] = { after: `${date.min}`, }
  } else if (!_.isNil(date.max)) {
    let max = new Date(date.max)
    max.setDate(max.getDate() + 1)

    variableparams.date[0] = { before: `${max.getFullYear()}-${max.getMonth() + 1}-${max.getDate()}`, }
  }

  return httpClient.post(END_POINT, {
    query: print(getIntegrationsErrorsQuery),
    variables: { ...variableparams },
  })
}

const getFileOfErrors = async (options) => {
  let params = {}
  let { date } = options

  if (!_.isNil(date.min) && !_.isNil(date.max)) {
    params = { 'date[after]': date.min, 'date[before]': date.max, }
  } else if (!_.isNil(date.min)) {
    params = { 'date[after]': date.min, }
  } else if (!_.isNil(date.max)) {
    params = { 'date[before]': date.max, }
  }

  return await httpClient.get(`${API_END_POINT}/pdf`, { params })
}

export {
  getIntegrationsErrors,
  getFileOfErrors,
}
