import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import './filters/filters'
import VueMeta from 'vue-meta'
import VueGoogleCharts from 'vue-google-charts'

Vue.config.productionTip = false

Vue.use(VueMeta)
Vue.use(VueGoogleCharts)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

Vue.filter