import httpClient from './httpClient'
import getBatteryTypesQuery from '@/graphql/queries/batteryTypes/list.gql'
import { print } from 'graphql/language/printer';

const END_POINT = process.env.VUE_APP_GRAPHQL_HTTP
const API_END_POINT = '/battery_types'

const getBatteryTypes = () => httpClient.post(END_POINT, { query: print(getBatteryTypesQuery) })

const findById = async (id) => await httpClient.get(`${API_END_POINT}/${id}`)

const create = async (item) => {
  item.ratedVoltage = parseFloat(item.ratedVoltage)
  item.ratedCapacity = parseInt(item.ratedCapacity)
  item.controlResistance = parseInt(item.controlResistance)
  item.shearStress = parseFloat(item.shearStress)

  return await httpClient.post(API_END_POINT, item)
}

const update = async (item) => {
  let id = item.id || null

  if (null === id) {
    throw new Error('No es posible actualizar el tipo de pila, ID es nulo.')
  }

  item.ratedVoltage = parseFloat(item.ratedVoltage)
  item.ratedCapacity = parseInt(item.ratedCapacity)
  item.controlResistance = parseInt(item.controlResistance)
  item.shearStress = parseFloat(item.shearStress)

  return await httpClient.put(`${API_END_POINT}/${id}`, item)
}

const remove = async (item) => {
  let id = item.id || null

  if (null === id) {
    throw new Error('No es posible eliminar el tipo de pila, ID es nulo.')
  }

  return await httpClient.delete(`${API_END_POINT}/${id}`)
}

const getBatteryTypesForCombo = async () => await httpClient.get(API_END_POINT, { params: { pagination: false } })

export {
  getBatteryTypes,
  findById,
  create,
  update,
  remove,
  getBatteryTypesForCombo,
}
