import { doLogin } from '@/api/auth'
import axios from 'axios'
import JwtUtils from '@/libraries/JwtUtils'

const state = {
  status: '',
  token: localStorage.getItem('token') || '',
  userInfo: {},
  userCanEdit: false
}

const actions = {
  LOGIN({ commit }, { username, password }) {
    return new Promise((resolve, reject) => {
      commit('auth_request')
      doLogin(username, password)
        .then(response => {
          const token = response.data.token
          localStorage.setItem('token', token)
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
          commit('auth_success', token)
          resolve(response)
        })
        .catch(error => {
          commit('auth_error')
          localStorage.removeItem('token')
          reject(error)
        })
    })
  },

  ASIGNAR_VALORES_USUARIO({commit}) {
      try {
        const token = localStorage.getItem('token')
        const jwtUtil = new JwtUtils()
        const jwtPayload = jwtUtil.getJwtTokenInfo(token)
        commit('set_user_info', jwtPayload)
      } catch(error) {
        console.error(error)
        commit('set_user_info', {})
      }
  },

  LOGOUT({ commit }) {
    return new Promise((resolve) => {
      commit('logout')
      localStorage.removeItem('token')
      resolve()
    })
  }
}

const mutations = {
  auth_request(state) {
    state.status = 'loading'
  },

  auth_success(state, token) {
    state.status = 'success'
    state.token = token
  },

  auth_error(state) {
    state.status = 'error'
  },

  set_user_info(state, jwtPayload) {
    state.userInfo = Object.assign({}, jwtPayload)
    state.userCanEdit = typeof state.userInfo.roles !== 'undefined' && state.userInfo.roles.includes('ROLE_SUPER_ADMIN')
  },

  logout(state) {
    state.status = ''
    state.token = ''
    state.userInfo = Object.assign({})
    state.userCanEdit = false
  }
}

const getters = {
  isLoggedIn: state => !!state.token,
  authStatus: state => state.status,
  userCanEdit: state => state.userCanEdit
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}